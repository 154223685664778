/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $('.slider').flexslider();
                $('.gallery').magnificPopup({
                  delegate: 'a',
                  type: 'image',
                  gallery: {
                    enable: true
                  }
                });
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                $(".social-feed").socialfeed({
                    facebook: {
                        accounts: ['~'], // keep socialfeed happy
                        limit: 1
                    },
                    show_media: true,
                    template: '/wp-content/themes/rkt-w2w/social.html'
                });

                // Mobile Menu Fire
                $(".menu-open").on('click', function() {
                    $("header nav").addClass('mobile-menu-show');
                });
                $(".menu-close").on('click', function() {
                    $("header nav").removeClass('mobile-menu-show');
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'contact_us': {
          init: function() {
            $('.contact-map').each(function() {
                $el = $(this);
                var $markers = $('.marker');

                var args = {
                    zoom : 16,
                    center : new google.maps.LatLng(0,0),
                    streetViewControl: false,
                    mapTypeId : google.maps.MapTypeId.ROADMAP,
                };

                var map = new google.maps.Map( $(this)[0], args);

                map.markers = [];

                $markers.each(function() {
                    var $marker = $(this);
                    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
                    var marker = new google.maps.Marker({
                        position: latlng, map: map
                    });
                    map.markers.push(marker);
                });

                var bounds = new google.maps.LatLngBounds();

                $.each(map.markers, function(i, marker) {
                    var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                    bounds.extend(latlng);
                });

                if (map.markers.length === 1 ) {
                    map.setCenter(bounds.getCenter());
                    map.setZoom(16);
                } else {
                    map.fitBounds( bounds );
                }
            });
          },
        },
        'distribution': {
            init: function() {
                $('.distribution-map').each(function() {
                    $el = $(this);
                    var $markers = $('.marker');
                    var currentInfoWindow;

                    var args = {
                        zoom : 16,
                        center : new google.maps.LatLng(0,0),
                        streetViewControl: false,
                        mapTypeId : google.maps.MapTypeId.ROADMAP,
                        //styles: [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]
                    };

                    var map = new google.maps.Map( $el.find('.map')[0], args);

                    map.markers = [];

                    $markers.each(function() {
                        var $marker = $(this);
                        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
                        var marker = new google.maps.Marker({
                            position: latlng, map: map
                        });
                        map.markers.push(marker);
                        if ($marker.html()) {
                            var infoWindow = new google.maps.InfoWindow({
                                content: $marker.html()
                            });
                            google.maps.event.addListener(marker, 'click', function() {
                                if (typeof currentInfoWindow !== 'undefined') {
                                  currentInfoWindow.close();
                                }
                                infoWindow.open(map, marker);
                                currentInfoWindow = infoWindow;
                            });
                        }
                    });

                    var bounds = new google.maps.LatLngBounds();

                    $.each(map.markers, function(i, marker) {
                        var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                        bounds.extend(latlng);
                    });

                    if (map.markers.length === 1 ) {
                        map.setCenter(bounds.getCenter());
                        map.setZoom(16);
                    } else {
                        map.fitBounds( bounds );
                    }

                    $el.find('button').on('click', function() {
                        var location = $el.find('[name="address"]').val();
                        var radius = $el.find('[name="distance"]').val()*1000;
                        var geocoder = new google.maps.Geocoder();
                        geocoder.geocode({'address': location}, function(results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                map.setCenter(results[0].geometry.location);
                                var centerPoint = new google.maps.Marker({
                                    map: map,
                                    position: results[0].geometry.location
                                });
                                if (typeof map.searchCircle !== 'undefined') {
                                  map.searchCircle.setMap(null);
                                }
                                map.searchCircle = new google.maps.Circle({
                                    center : centerPoint.getPosition(),
                                    radius : radius,
                                    fillOpacity : 0.50,
                                    fillColor: '#8bc34a',
                                    map : map
                                });
                                var searchBounds = new google.maps.LatLngBounds();
                                $.each(map.markers, function(i, marker) {
                                    if (google.maps.geometry.spherical.computeDistanceBetween(marker.getPosition(), centerPoint.getPosition()) < radius) {
                                        searchBounds.extend(marker.getPosition());
                                    }
                                });
                                map.fitBounds(searchBounds);
                            } else {
                                console.log('Unable to geocode address');
                            }
                        });
                    });

                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
